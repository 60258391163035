import React, { useEffect } from "react";
import { navigate } from "gatsby";

const IndexPage = () => {
  useEffect(() => {
    navigate("/product/2022-campaign/");
  }, []);

  return <div />;
};

export default IndexPage;
